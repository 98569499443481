<template>
  <div class="row">
      <div v-if="!loaded">
          <svg-icon name="spinner"/>
          Loading data. Please wait
      </div>
    <div :style="{color: item.status_detail.theme_color}"
         class="sale_item col-xl-4 col-md-6 col-sm-6 col-6 pl-md-3 py-3 d-flex flex-column justify-content-sm-center"
         v-for="item in orders"
         v-else-if="orders && orders.length > 0"
         :key="item.id">
      <div class="sale_headlineIcon d-flex justify-content-md-left">
        <svg-icon :name="item.status_detail.state_icon" />
      </div>
      <div class="sale_body">
            <span class="thumbnailsTable__id">
                <svg-icon name="file-alt"
                                   :style="{color: item.status_detail.theme_color}"
                                   v-bind:class="[item.status.toLowerCase(), item.type.toLowerCase()]"/><span>{{item.id}}</span>
            </span>
        <span class="thumbnailsTable__user">
             <svg-icon name="user"
                                :style="{color: item.status_detail.theme_color}"
                                v-bind:class="[item.status.toLowerCase(), item.type.toLowerCase()]"/><span>{{item.user}}</span>
            </span>
        <span class="thumbnailsTable__status">
                  <svg-icon :name="item.status_detail.status_icon" :style="{color: item.status_detail.theme_color}"/>
                  <span>{{ shortenText(item.status_detail.status_description) }}</span>
            </span>
        <span class="thumbnailsTable__cost">

              <svg-icon name="solid/sack-dollar" :style="{color: item.status_detail.theme_color}"/><span>{{item.cost}}</span>
            </span>
      </div>
      <button class="btn btn_default" @click="goTo(item)"><svg-icon name="solid/file-check" /> VIEW {{item.type}}</button>
    </div>
      <div v-else class="col">
          No orders found
      </div>
  </div>
</template>

<script>

    import router from "../../router";

export default {
  name: 'OrderItems',
    methods:{
        goTo(item){
          router.push({name : 'order-preview',params: { id: item.id }} );
        }
    },
  computed: {
    orders() {
      return this.$store.getters['ordersList/orders'];
    },
      loaded() {
          return this.$store.getters['ordersList/loaded'];
      },
  },
    mounted(){
        this.$store.dispatch('ordersList/loadOrders');
    }

};
</script>

<style scoped>


</style>
