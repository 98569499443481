<template>
  <div class="orders col-xl-6 col-sm-6 pr-sm-2">
    <div class="divider divider_bold">
      <div class="text_white titlePage_subtitle titlePage_bold">
        <h3>MY ORDERS</h3>
      </div>
    </div>
    <div class="sale_items__wrapper pl-md-3">
      <OrderItems />
      <router-link :to="{name: 'orderList', query: { orderType: 'orders'}}" class="btn btn_default">
        <svg-icon name="solid/clock-rotate-left" /> VIEW RECENT ACTIVITY</router-link>
    </div>
  </div>
</template>

<script>
import OrderItems from './OrderItems';

export default {
  name: 'Orders',
  components: { OrderItems },
};
</script>

<style scoped lang="scss">
  .titlePage_subtitle {
    padding-left: 20px;
  }

  .divider{
    &:before{
      display: none;
    }
  }
</style>
